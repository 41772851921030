import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Milano - Sanremo",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-19 10:29:02",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21847: {
        teamId: "21847",
        teamUciCode: "DRA",
        teamName: "Drone Hopper-Androni Giocattoli",
        teamNationCode: "ITA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22591: {
        teamId: "22591",
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        teamNationCode: "ITA",
      },
      23694: {
        teamId: "23694",
        teamUciCode: "EOK",
        teamName: "EOLO-Kometa Cycling Team",
        teamNationCode: "ITA",
      },
    },
    riders: {
      45987: {
        id: 45987,
        startno: 232,
        firstName: "Alessandro",
        lastName: "Covi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-28",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      20397: {
        id: 20397,
        startno: 5,
        firstName: "Jacopo",
        lastName: "Mosca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-08-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37294: {
        id: 37294,
        startno: 76,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      18490: {
        id: 18490,
        startno: 143,
        firstName: "Alexander",
        lastName: "Cataford",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1993-09-01",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      17203: {
        id: 17203,
        startno: 113,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      780: {
        id: 780,
        startno: 72,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      20584: {
        id: 20584,
        startno: 91,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 44,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 1,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      2313: {
        id: 2313,
        startno: 125,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      27097: {
        id: 27097,
        startno: 152,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      6413: {
        id: 6413,
        startno: 13,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      3355: {
        id: 3355,
        startno: 202,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 201,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      76800: {
        id: 76800,
        startno: 87,
        firstName: "Ricardo",
        lastName: "Zurita",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2000-11-10",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      7731: {
        id: 7731,
        startno: 147,
        firstName: "Rick",
        lastName: "Zabel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      580: {
        id: 580,
        startno: 103,
        firstName: "Francesco",
        lastName: "Gavazzi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-08-01",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      729: {
        id: 729,
        startno: 156,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16144: {
        id: 16144,
        startno: 163,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      2530: {
        id: 2530,
        startno: 191,
        firstName: "Nacer",
        lastName: "Bouhanni",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-07-25",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      27101: {
        id: 27101,
        startno: 145,
        firstName: "Omer",
        lastName: "Goldstein",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1996-08-13",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 32,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 95,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      1717: {
        id: 1717,
        startno: 155,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      1181: {
        id: 1181,
        startno: 193,
        firstName: "Romain",
        lastName: "Hardy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-08-24",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      66439: {
        id: 66439,
        startno: 57,
        firstName: "Filippo",
        lastName: "Zana",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-18",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 237,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      14126: {
        id: 14126,
        startno: 77,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16998: {
        id: 16998,
        startno: 234,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 187,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      918: {
        id: 918,
        startno: 3,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      22010: {
        id: 22010,
        startno: 104,
        firstName: "Mirco",
        lastName: "Maestri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-10-26",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      23372: {
        id: 23372,
        startno: 37,
        firstName: "Artyom",
        lastName: "Zakharov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1991-10-27",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 22,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      27307: {
        id: 27307,
        startno: 171,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 197,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      80489: {
        id: 80489,
        startno: 43,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 215,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 27,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 12,
        firstName: "Beno\u00c3\u00aet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 45,
        firstName: "Jasha",
        lastName: "S\u00c3\u00bctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      27275: {
        id: 27275,
        startno: 134,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      38164: {
        id: 38164,
        startno: 97,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      69912: {
        id: 69912,
        startno: 167,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      964: {
        id: 964,
        startno: 226,
        firstName: "Julien",
        lastName: "Simon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1985-10-04",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      46012: {
        id: 46012,
        startno: 162,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-22",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      30020: {
        id: 30020,
        startno: 52,
        firstName: "Luca",
        lastName: "Covili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      2741: {
        id: 2741,
        startno: 2,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 63,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      9073: {
        id: 9073,
        startno: 136,
        firstName: "Loic",
        lastName: "Vliegen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-12-20",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      37397: {
        id: 37397,
        startno: 117,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-07-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      20454: {
        id: 20454,
        startno: 73,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-12",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      14128: {
        id: 14128,
        startno: 86,
        firstName: "Edoardo",
        lastName: "Zardini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-02",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 235,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 93,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      45349: {
        id: 45349,
        startno: 123,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-09-18",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      2982: {
        id: 2982,
        startno: 141,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      341: {
        id: 341,
        startno: 114,
        firstName: "Ignatas",
        lastName: "Konovalovas",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1985-12-08",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      82912: {
        id: 82912,
        startno: 217,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-10-16",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      767: {
        id: 767,
        startno: 206,
        firstName: "Cameron",
        lastName: "Meyer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-01-11",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      46167: {
        id: 46167,
        startno: 173,
        firstName: "I\u00c3\u00b1igo",
        lastName: "Elosegui",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      840: {
        id: 840,
        startno: 165,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      50047: {
        id: 50047,
        startno: 102,
        firstName: "Davide",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-04-02",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 21,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 64,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 153,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16646: {
        id: 16646,
        startno: 65,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      14717: {
        id: 14717,
        startno: 36,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      31: {
        id: 31,
        startno: 222,
        firstName: "Edvald",
        lastName: "Boasson-Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 151,
        firstName: "Wout",
        lastName: "van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      161: {
        id: 161,
        startno: 192,
        firstName: "Maxime",
        lastName: "Bouet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-11-03",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      33402: {
        id: 33402,
        startno: 53,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      16803: {
        id: 16803,
        startno: 236,
        firstName: "Oliviero",
        lastName: "Troia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-01",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      66495: {
        id: 66495,
        startno: 61,
        firstName: "Giovanni",
        lastName: "Aleotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      14778: {
        id: 14778,
        startno: 82,
        firstName: "Eduard Michael",
        lastName: "Grosu",
        nationCode: "ROM",
        nationName: "Romania",
        birthDate: "1992-09-04",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      99643: {
        id: 99643,
        startno: 81,
        firstName: "Didier",
        lastName: "Merchan",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-07-26",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      14712: {
        id: 14712,
        startno: 212,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      34369: {
        id: 34369,
        startno: 105,
        firstName: "Samuele",
        lastName: "Rivi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-11",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      27257: {
        id: 27257,
        startno: 35,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 154,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 174,
        firstName: "Iv\u00c3\u00a1n",
        lastName: "Garc\u00c3\u00ada Cortina",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      1009: {
        id: 1009,
        startno: 144,
        firstName: "Alex",
        lastName: "Dowsett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-10-03",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      1006: {
        id: 1006,
        startno: 42,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      8740: {
        id: 8740,
        startno: 4,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      27190: {
        id: 27190,
        startno: 172,
        firstName: "William",
        lastName: "Barta",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-01-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      21855: {
        id: 21855,
        startno: 116,
        firstName: "Miles",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-01-18",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      12481: {
        id: 12481,
        startno: 186,
        firstName: "Florian",
        lastName: "S\u00c3\u00a9n\u00c3\u00a9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      16816: {
        id: 16816,
        startno: 41,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      16823: {
        id: 16823,
        startno: 224,
        firstName: "Niccolo",
        lastName: "Bonifazio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      7730: {
        id: 7730,
        startno: 67,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      70377: {
        id: 70377,
        startno: 166,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      349: {
        id: 349,
        startno: 14,
        firstName: "Mika\u00c3\u00abl",
        lastName: "Ch\u00c3\u00a9rel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-03-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      6227: {
        id: 6227,
        startno: 184,
        firstName: "Mattia",
        lastName: "Cattaneo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      46003: {
        id: 46003,
        startno: 24,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 223,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      7580: {
        id: 7580,
        startno: 15,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      17179: {
        id: 17179,
        startno: 194,
        firstName: "Kevin",
        lastName: "Ledanois",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-13",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      20466: {
        id: 20466,
        startno: 54,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 31,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      33341: {
        id: 33341,
        startno: 85,
        firstName: "Filippo",
        lastName: "Tagliani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-14",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 231,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37401: {
        id: 37401,
        startno: 185,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      65025: {
        id: 65025,
        startno: 124,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      20378: {
        id: 20378,
        startno: 137,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 7,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      784: {
        id: 784,
        startno: 221,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      18079: {
        id: 18079,
        startno: 204,
        firstName: "Alex",
        lastName: "Edmondson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-12-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      25690: {
        id: 25690,
        startno: 177,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      37375: {
        id: 37375,
        startno: 96,
        firstName: "James",
        lastName: "Shaw",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-13",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      1310: {
        id: 1310,
        startno: 127,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 66,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 23,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      2337: {
        id: 2337,
        startno: 111,
        firstName: "Arnaud",
        lastName: "D\u00c3\u00a9mare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      46161: {
        id: 46161,
        startno: 94,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      1580: {
        id: 1580,
        startno: 33,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 131,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      45363: {
        id: 45363,
        startno: 25,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 196,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      1579: {
        id: 1579,
        startno: 51,
        firstName: "Sacha",
        lastName: "Modolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-06-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      11011: {
        id: 11011,
        startno: 233,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      68052: {
        id: 68052,
        startno: 55,
        firstName: "Omar",
        lastName: "El Gouzi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-08-16",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      64479: {
        id: 64479,
        startno: 175,
        firstName: "Abner",
        lastName: "Gonz\u00c3\u00a1lez",
        nationCode: "PUR",
        nationName: "Puerto Rico",
        birthDate: "2000-10-09",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      10916: {
        id: 10916,
        startno: 84,
        firstName: "Jhonatan",
        lastName: "Restrepo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-11-28",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      353: {
        id: 353,
        startno: 115,
        firstName: "Anthony",
        lastName: "Roux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-04-18",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37351: {
        id: 37351,
        startno: 101,
        firstName: "Vincenzo",
        lastName: "Albanese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-12",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 47,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 46,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      92666: {
        id: 92666,
        startno: 132,
        firstName: "Biniam",
        lastName: "Girmay",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "2000-04-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      828: {
        id: 828,
        startno: 126,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      777: {
        id: 777,
        startno: 225,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 112,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      14123: {
        id: 14123,
        startno: 17,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      42185: {
        id: 42185,
        startno: 211,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      346: {
        id: 346,
        startno: 135,
        firstName: "Rein",
        lastName: "Taaram\u00c3\u00a4e",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      37756: {
        id: 37756,
        startno: 176,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      14122: {
        id: 14122,
        startno: 106,
        firstName: "Diego",
        lastName: "Rosa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-03-27",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      7729: {
        id: 7729,
        startno: 216,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      174: {
        id: 174,
        startno: 164,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 227,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      65240: {
        id: 65240,
        startno: 182,
        firstName: "Andrea",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-23",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 26,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 121,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16583: {
        id: 16583,
        startno: 157,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      3133: {
        id: 3133,
        startno: 195,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      20471: {
        id: 20471,
        startno: 56,
        firstName: "Alessandro",
        lastName: "Tonelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-29",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      22389: {
        id: 22389,
        startno: 16,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      20386: {
        id: 20386,
        startno: 83,
        firstName: "Umberto",
        lastName: "Marengo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-07-21",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 207,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 213,
        firstName: "S\u00c3\u00b8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      9154: {
        id: 9154,
        startno: 75,
        firstName: "Pierre-Luc",
        lastName: "P\u00c3\u00a9richon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-01-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      3147: {
        id: 3147,
        startno: 133,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      16660: {
        id: 16660,
        startno: 146,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      9008: {
        id: 9008,
        startno: 6,
        firstName: "Simon",
        lastName: "Pellaud",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-11-06",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 203,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      37393: {
        id: 37393,
        startno: 107,
        firstName: "Diego",
        lastName: "Sevilla",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-03-04",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 92,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      27099: {
        id: 27099,
        startno: 122,
        firstName: "Filippo",
        lastName: "Ganna",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-07-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      37748: {
        id: 37748,
        startno: 181,
        firstName: "Fabio",
        lastName: "Jakobsen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-08-31",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      875: {
        id: 875,
        startno: 142,
        firstName: "Matthias",
        lastName: "Br\u00c3\u00a4ndle",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1989-12-07",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      16752: {
        id: 16752,
        startno: 71,
        firstName: "Bryan",
        lastName: "Coquard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 74,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 34,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      66973: {
        id: 66973,
        startno: 214,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 11,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      27111: {
        id: 27111,
        startno: 183,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      39286: {
        id: 39286,
        startno: 205,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Milano Sanremo"
  const raceID = 4

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
